@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Newsreader Display";
    font-style: normal;
    font-weight: 200 800;
    font-display: swap;
    src: url(./NewsreaderDisplay.woff2) format("woff2");
  }

  html,
  body,
  #__next {
    height: 100%;
    @apply scroll-smooth;
  }
  body {
    @apply font-sans font-normal text-sand-700;
  }

  /* gets rid of that highlight when you tap buttons on mobile */
  * {
    -webkit-tap-highlight-color: transparent;
  }

  *:focus {
    outline: none;
    @apply focused;
  }

  *:focus:not(:focus-visible) {
    @apply unfocused;
  }
}

@layer components {
  .spinner .spinner-gradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: conic-gradient(rgba(0, 0, 0, 0), currentColor);
  }

  .pool-layout {
    @apply grid gap-y-12 gap-x-10;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "widgets"
      "info";
  }
  @media screen(lg) {
    .pool-layout {
      grid-template-columns: calc(45% - theme(spacing.5)) calc(
          55% - theme(spacing.5)
        );
      grid-template-areas: "widgets info";
    }
  }
}

@layer utilities {
  .focused {
    @apply ring-2 ring-eggplant-300;
  }
  .unfocused {
    @apply ring-0;
  }
}

/* Toastify custom styles */
:root {
  --toastify-color-progress-light: #000;
  --toastify-color-success: theme("colors.grass.100");
  --toastify-color-warning: #fbf7d5;
  --toastify-color-error: theme("colors.clay.100");
  --toastify-text-color-success: theme("colors.grass.800");
  --toastify-text-color-warning: #645c18;
  --toastify-text-color-error: theme("colors.clay.800");
}

.Toastify__toast-container {
  @apply w-full max-w-screen-md;
}

.Toastify__close-button {
  color: inherit;
}

.Toastify__progress-bar-theme--colored,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: currentColor;
}
